// eslint-disable-next-line import/no-anonymous-default-export
export default {
  navBar: {
    domaine: {
      fr: "domaine",
      en: "the estate",
    },
    wines: {
      fr: "nos vins",
      en: "our wines",
    },
    store: {
      fr: "boutique",
      en: "wine shop",
    },
    news: {
      fr: "nos actualités",
      en: "our news",
    },
    contact: {
      fr: "contact",
      en: "contact",
    }
  },
  domainePage: {
    p1: {
      fr: "Créé en 1947 par François et Claudine BERNILLON, la ferme était à l'origine une petite exploitation agricole avec quelques animaux en plus de la vigne. En 1973, le Domaine est repris par le fils Alain BERNILLON et son épouse Bernadette, l'élevage est arrêté afin de développer pleinement le travail de la vigne. De nouveaux marchés se développent Belgique, Restaurant Parisien, particuliers sur toute la France. En 2015, leur fille Mariannick et son mari Guillaume DUMONTET reprennent le Domaine. Une nouvelle dynamique est donnée pour développer le digital, la gamme de vin est élargie. Les pratiques sont revues pour être plus respectueuses de l'environnement.",
      en: "Created in 1947 by François and Claudine BERNILLON, the farm was originally a small farm with some animals in addition to the vine. In 1973, the Domaine is taken over by the son Alain BERNILLON and his wife Bernadette, the animal breeding is stopped in order to fully develop the work of the vineyard. New markets are developing Belgium, Restaurant Parisien, individuals on the whole France. In 2015, their daughter Mariannick and her husband Guillaume DUMONTET took over the Domaine. A new dynamic is given to develop digital, the range of wine is expanded. Practices are reviewed to be more environmentally friendly.",
    },
    p2: {
      fr: "Le Domaine, situé à St Lager, s'étend sur 8 hectares. Nous produisons principalement du Brouilly, du Côte de Brouilly et depuis peu des Beaujolais Villages Rouge et Rosé. Nous travaillons dans un cadre familial et gérons tout le processus du travail de la vigne à la commercialisation. Nous vendons 80% de notre production directement au particulier.",
      en: "The Estate, located in St Lager, covers 8 hectares. We produce mainly Brouilly, Côte de Brouilly and recently Beaujolais Villages Red and Rosé. We work in a family setting and manage the entire process of the work from the vineyard to the commercialization. We sell 80% of our production directly."
    },
    p3: {
      fr: "Nous sommes certifiés HVE (Haute Valeur Environnementale), nous sommes en cours de Certification Biologique. Nous travaillons nos vignes manuellement : taille de coupe à la main, vendanges manuelles, attache de la vigne, ... La vinification se fait entièrement dans nos caves.",
      en: "We have HVE certification (High Environmental Value), we are in the process of Organic Certification. We work our vines manually: cup size by hand, manual harvesting, attachment of the vine, ... The vinification is done entirely in our cellars."
    }
  },
  winesPage: {
    comment: {
      fr: "commentaire de dégustation",
      en: "tasting comments",
    },
    accord: {
      fr: "accord mets et vins",
      en: "food and wine pairing",
    },
    info: {
      title: {
        fr: "Information sur le vin",
        en: "Information about the wine",
      },
      domaine: {
        fr: "Domaine",
        en: "Domaine",
      },
      region: {
        fr: "Région",
        en: "Region",
      },
      appell: {
        fr: "Appellation",
        en: "Appellation",
      },
      cepage: {
        fr: "Cépage",
        en: "Vine Stock",
      },
      condition: {
        fr: "Conditionnement",
        en: "Conditioning",
      },
      temp: {
        fr: "Température de Servie",
        en: "Best Served At",
      },
      garde: {
        fr: "Garde",
        en: "Aging",
      },
      details: {
        fr: "CARACTERISQUE DU VIN",
        en: "WINE CHARACTERISTICS",
      },
      fruite: {
        fr: "Fruité",
        en: "Fruit",
      },
      mineral: {
        fr: "Minéral",
        en: "Mineral",
      },
      epice: {
        fr: "épice",
        en: "spices",
      } ,
      puissance: {
        fr: "puissance",
        en: "body",
      },
      floral: {
        fr: "floral",
        en: "flower",
      },
      boise: {
        fr: "boisé",
        en: "aok",
      } ,
      acidite: {
        fr: "acidité",
        en: "acidity",
      },
    },
    Elixir : {
      price: "16,75",
      region : "Beaujolais",
      appel : "Côte de Brouilly",
      cepage : "Gamay ",
      conditionnement : "0.75 cL",
      color: {
        fr: "Rouge",
        en: "Red",
      },
      desc : {
        fr: "Une trame aromatique de fruits rouges bien mûrs accompagné d'une subtile nuance vanillée et boisée dûe à l'élevage de 8 mois en fûts de chêne. Vous pouvez le savourer dès à présent ou le déguster dans quelques années selon vos préférences. Découvrez cet Elixir du Beaujolais et laissez-vous surprendre par sa complexité.",
        en: "An complex aromatic mix of ripe red fruits accompanied by a subtle vanilla and woody nuance due to the aging of 8 months in oak barrels. You can enjoy it now or taste it in a few years according to your preferences. Discover this Beaujolais Elixir and let yourself be surprised by its complexity."
      },
      caract : {
        fruite: 3,
        mineral: 1,
        epice: 1, 
        puissance: 3,
        floral: 2,
        boise: 2, 
        acidite: 2,
      },
      garde: "3 à 7 ans (garde moyenne)",
      temp: "12 - 14°C",
      assoc : {
        fr: "Fromages, hors d'oeuvre chauds et tartes salées, légumes, terrines et pâtés, viandes blanches grillées et rôties, viandes en sauce claire, viandes rouges grillées et rôties",
        en: "Cheeses, hot appetizers and savory pies, vegetables, terrines and pâtés, grilled and roasted white meats, meats in light sauce, grilled and roasted red meats",
      },
    },
    Godefroy : {
      price: "14,45",
      region : "Beaujolais",
      appel : "Côte de Brouilly",
      cepage : "Gamay ",
      conditionnement : "0.75 cL",
      color: {
        fr: "Rouge",
        en: "Red",
      },
      desc : {
        fr: "Souple, ronde et harmonieuse cette cuvée Godefroy s'ouvre sur un nez relativement discret. La bouche est quant à elle beaucoup plus intense. Des notes de fraises des bois et de framboise s'accordent parfaitement avec sa minéralité et ses tanins fins. La pivoine est également présente en milieu de bouche ce qui apporte un côté floral très flatteur.",
        en :"Supple, round and harmonious, this Godefroy cuvée opens with a relatively discreet nose. The body is much more intense. Notes of wild strawberries and raspberry go perfectly with its minerality and fine tannins. Peony is also present in the mid-body which brings a very flattering floral side.",
      },
      caract : {
        fruite: 3,
        mineral: 3,
        epice: 1, 
        puissance: 4,
        floral: 2,
        boise: 1, 
        acidite: 2,
      },
      garde: "3 à 7 ans (garde moyenne)",
      temp: "12 - 14°C",
      assoc : {
        fr: "Fromages, légumes, pâtes et riz, terrines et pâtés, viandes en sauce brune, viandes rouges grillées et rôties",
        en: "Cheeses, vegetables, pasta and rice, terrines and pâtés, dark meats with a dark sauce , grilled and roasted red meats",
      },
    },
    Brouilly : {
      price: "12,55",
      region : "Beaujolais",
      appel : "Brouilly",
      cepage : "Gamay ",
      conditionnement : "0.75 cL",
      color: {
        fr: "Rouge",
        en: "Red",
      },
      desc : {
        fr: "Voilà un Gamay qui ne manque pas de caractère ! Des notes de mûre, figue et prune confite animent la bouche. Rond, souple et fruité il se veut convivial et harmonieux. Préparez un buffet froid pour accompagner ce vin ou un chapon à la sauce forestière.",
        en: "This is a Gamay that does not lack character! Notes of blackberry, fig and candied plum enliven the body. Round, supple and fruity, it is friendly and harmonious. Prepare a cold buffet to accompany this wine or a capon with forest sauce.",
      },
      caract : {
        fruite: 3,
        mineral: 1,
        epice: 1, 
        puissance: 4,
        floral: 2,
        boise: 1, 
        acidite: 3,
      },
      garde: "3 à 7 ans (garde moyenne)",
      temp: "12 - 14°C",
      assoc : {
        fr: "Charcuteries et viandes salées, hors d'oeuvre froids et salades, hors d’oeuvre chauds et tartes salées, pâtes et riz, terrines et pâtés, viandes blanches grillées et rôties, viandes en sauce brune, viandes en sauce claire, viandes rouges grillées et rôties",
        en: "Cold cuts and cured meats, cold appetizers and salads, hot appetizers and savory pies, pasta and rice, terrines and pâtés, grilled and roasted white meats, meats in brown sauce, meats in light sauce, grilled red meats and roasted",
      },
    },
    CDB : {
      price: "12,55",
      region : "Beaujolais",
      appel : "Côte de Brouilly",
      cepage : "Gamay ",
      conditionnement : "0.75 cL",
      color: {
        fr: "Rouge",
        en: "Red",
      },
      desc : {
        fr: "Ce cru du Beaujolais se caractérise par un fruité intense et une charpente bien présente. Des notes de mûres et de cassis en bouche accompagnent les tanins tendres de ce millésimé. Il est élégant et le fruité croquant de ses arômes saura charmer le palais le plus délicat.",
        en: "This Beaujolais cru is characterized by an intense fruitiness and a very present structure. Notes of blackberries and cassis on the palate accompany the soft tannins of this vintage. It is elegant and the crunchy fruitiness of its aromas will charm the most delicate palate",
      },
      caract : {
        fruite: 4,
        mineral: 2,
        epice: 1, 
        puissance: 4,
        floral: 2,
        boise: 1, 
        acidite: 2,
      },
      garde: "3 à 7 ans (garde moyenne)",
      temp: "12 - 14°C",
      assoc : {
        fr: "Charcuteries et viandes salées, hors d'oeuvre froids et salades, pâtes et riz, Soupes et potages, terrines et pâtés, tiandes blanches grillées et rôties, tiandes rouges grillées et rôties",
        en: "Deli meats and salted meats, cold appetizers and salads, pasta and rice, soups and soups, terrines and pâtés, grilled and roasted white meats, grilled and roasted red meats.",
      },
    },
    Passion : {
      price: "10,70",
      region : "Beaujolais",
      appel : "Beaujolais Village",
      cepage : "Gamay ",
      conditionnement : "0.75 cL",
      color: {
        fr: "Rosé",
        en: "Rosé",
      },
      desc : {
        fr: "Mordant, frais, ce rosé 'Passion' du domaine des Fournelles est parfait pour ces chaudes journées d'été qui s'annoncent. Il saura vous rafraichir et vous égayer avec ces notes fruitées et acidulées. La finale autour du bonbon est surprenante et gourmande.",
        en: "Punchy, fresh, this 'Passion' rosé from the Domaine des Fournelles is perfect for those hot summer days ahead. It will refresh and cheer you up with its fruity and tangy notes. The candy is surprising and tasty.",
      },
      caract : {
        fruite: 3,
        mineral: 2,
        epice: 0, 
        puissance: 2,
        floral: 1,
        boise: 0, 
        acidite: 4,
      },
      garde: "1 à 3 ans (à boire jeune)",
      temp: "8 - 10°C",
      assoc :{ 
        fr: "Apéritif, hors d'oeuvre froids et salades, pâtes et riz, viandes blanches grillées et rôties, viandes en sauce claire, oeufs",
        en: "Aperitif, cold appetizers and salads, pasta and rice, grilled and roasted white meats, meats in light sauce, eggs",
      },
    },
    BeaujolaisVillage : {
      price: "8,90",
      region : "Beaujolais",
      appel : "Beaujolais Village",
      cepage : "Gamay ",
      conditionnement : "0.75 cL",
      color: {
        fr: "Rouge",
        en: "Red",
      },
      desc : {
        fr: "Ce Beaujolais Villages élaboré traditionnellement vous surprendra par sa rondeur, son fruité et sa gourmandise. De légères notes de fruits rouges comme la cerise ou la groseille viendront égayer vos papilles. Dégustez le entre amis avec un barbecue ou une plancha et vous serez comblé!",
        en: "This traditionally produced Beaujolais Villages will surprise you with its fruitiness, delicacy and rounded flavor. Slight notes of red fruits such as cherry or redcurrant will brighten up your taste buds. Enjoy it with friends at a barbecue or around a plancha and you will be delighted!f",
      },
      caract : {
        fruite: 4,
        mineral: 0,
        epice: 1, 
        puissance: 3,
        floral: 2,
        boise: 0, 
        acidite: 2,
      },
      garde: "1 à 3 ans (à boire jeune)",
      temp: "12 - 14°C",
      assoc : {
        fr: "Charcuteries et viandes salées, hors d’oeuvre chauds et tartes salées, légumes, âtes et riz, soupes et potages, terrines et pâtés, viandes rouges grillées et rôties",
        en: "Cold meats and salted meats, hot appetizers and savory pies, vegetables, pasta and rice, soups and soups, terrines and pâtés, grilled and roasted red meats",
      },
    },
    SansArtifice : {
      price: "16,10",
      region : "Beaujolais",
      appel : "Brouilly",
      cepage : "Gamay ",
      conditionnement : "0.75 cL",
      color: {
        fr: "Rouge",
        en: "Red",
      },
      desc : {
        fr: "Un somptueux Brouilly sans sulfites ajoutés d'une belle couleur rubis. Des arômes frais et fruités s'ouvrent au nez sur des touches de fruits noirs : myrtille et mûre. Tendue et équilibrée, la bouche révèle une finale délicatement schisteuse et crayeuse apportant du relief à l'ensemble. Une finale longue et droite qui prolonge le plaisir de longues secondes ! L'étiquette et le texte présent sur la contre étiquette ont été pensées en collaboration avec monsieur Dumontet, le vigneron, et Maïa Mazaurette autrice et peintre française très connue du grand public. Autant d'atout invitant à découvrir cette délicieuse cuvée.",
        en: "A sumptuous Brouilly without added sulphites with a beautiful ruby color. Fresh and fruity aromas open on the nose with touches of black fruit: blueberry and blackberry. Taut and balanced, the body reveals a delicately schistous and chalky finish bringing depth to the wine. A long, straight finish that prolongs the pleasure for the seconds that follow! The label and the text on the back were designed in collaboration with Mr. Dumontet, the winemaker, and Maïa Mazaurette, a French author and painter well known to the general public. All these particularites are a calling card to discover this delicious vintage.",
      },
      caract : {
        fruite: 4,
        mineral: 3,
        epice: 0, 
        puissance: 3,
        floral: 1,
        boise: 0, 
        acidite: 2,
      },
      garde: "1 à 3 ans (à boire jeune)",
      temp: "14 - 16°C",
      assoc : {
        fr: "Charcuteries et viandes salées, desserts, légumes, pâtes et riz",
        en: "Deli meats and cured meats, desserts, vegetables, pasta and rice",
      },
    },
  },
  contactPage: {
    info1: {
      fr: "Envoyez nous un message",
      en: "Send us a message",
    },
    thanks: {
      fr: "Merci pour votre message.",
      en: "Thank you for your message",
    },
    fname: {
      fr: "Prénom",
      en: "First Name",
    },
    lname: {
      fr: "Nom",
      en: "Last Name",
    },
    tel: {
      fr: "Numéro de téléphone",
      en: "Phone number",
    },
    email: {
      fr: "Adresse E-mail",
      en: "Email Address",
    },
    message: {
      fr: "Votre message.",
      en: "Your message",
    },
    button: {
      fr: "envoyer",
      en: "send",
    },
  },
};